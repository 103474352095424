import type { DirectusUsers } from '~/types/directus.types'

export const oceanaRoles = {
  captain: `${import.meta.env.VITE_OCEANA_ROLE_CAPTAIN}`,
  sailor: `${import.meta.env.VITE_OCEANA_ROLE_SAILOR}`,
  public: 'public',
} as const

export type OceanaRole = keyof typeof oceanaRoles
export default () => {
  const getRole = (me: DirectusUsers | undefined): OceanaRole => {
    switch (me?.role) {
      case oceanaRoles.captain: return 'captain'
      case oceanaRoles.sailor: return 'sailor'
      default: return 'public'
    }
  }

  return {
    getRole,
    oceanaRoles,
  }
}
