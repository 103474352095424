import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp

    const config = useRuntimeConfig()

    Sentry.init({
      app: vueApp,
      // eslint-disable-next-line node/prefer-global/process
      environment: process.env.NODE_ENV as string ?? undefined,
      dsn: config.public.SENTRY_DSN_PUBLIC as string ?? undefined,
      autoSessionTracking: false,
    })
  },
})
