import type { RouteRecordName } from 'vue-router'
import type { OceanaRole } from '~/composables/useRoles'
import type { DirectusUsers } from '~/types/directus.types'

const captainRoutes = [
  'admin',
  'admin-',
]
const sailorRoutes = [
  'me',
  'index',
  'sea',
  'sea-',
]
const nonAuthenticatedRoutes = [
  'login',
  'imprint',
  'privacy',
]

function routeMatchesRoutes(route: RouteRecordName, routes: string[]): boolean {
  let matched = false
  routes.forEach((r) => {
    if (r.endsWith('-')) {
      if (String(route).startsWith(r))
        matched = true
    }
    else {
      if (String(route) === r)
        matched = true
    }
  })
  return matched
}

export function getDefaultRouteForRole(role: OceanaRole | undefined) {
  switch (role) {
    case 'captain':
      return '/admin'
    case 'sailor':
      return '/'
    default:
      return '/login'
  }
}

export function roleMatchesRoute(role: OceanaRole, route: string): true | string {
  const routeRole = getRoleForRoute(route)
  if (routeRole && role !== routeRole)
    return getDefaultRouteForRole(role)
  else
    return true
}

export function getRoleForRoute(route: RouteRecordName | null | undefined): OceanaRole | undefined {
  if (typeof route === 'undefined' || route === null)
    return undefined
  if (routeMatchesRoutes(route, nonAuthenticatedRoutes))
    return 'public'
  if (routeMatchesRoutes(route, sailorRoutes))
    return 'sailor'
  if (routeMatchesRoutes(route, captainRoutes))
    return 'captain'
  return undefined
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  useState('crumbs').value = []
  if (from.name === to.name)
    await useDirectus().getUser(true)

  const me = useState<DirectusUsers | 'unauthorized' | undefined>('me')

  let role: OceanaRole = 'public'

  if (me.value && me.value !== 'unauthorized' && 'role' in me.value)
    role = me.value.role as OceanaRole

  const rightRoute = roleMatchesRoute(role, to.name as string)

  if (rightRoute === true)
    return

  const query: Record<string, string> = {}
  if (to.params.sea)
    query.ref = to.params.sea as string
  return navigateTo({ path: rightRoute, query: { ...query, ...to.query } })
})
