export default defineAppConfig({
  ui: {
    primary: 'oceana',
    gray: 'base',
    notifications: {
      position: 'top-auto bottom-0',
    },
    input: {
      default: {
        size: 'lg',
      },
    },
    container: {
      constrained: 'max-w-5xl',
    },
  },
})
