import {
  aggregate,
  authentication,
  createDirectus,
  createItem,
  deleteItem,
  graphql,
  importFile,
  readItem,
  readItems,
  readMe,
  readUsers,
  rest,
  updateItem,
  updateMe,
  uploadFiles,
  utilitySort,
} from '@directus/sdk'
import type { CustomDirectusTypes, DirectusUsers } from '~/types/directus.types'

class LocalStorage {
  key: string
  constructor(key: string) {
    this.key = key
  }

  get() {
    const item = localStorage.getItem(this.key)
    if (item)
      return JSON.parse(item)
    return null
  }

  set(data: unknown) {
    localStorage.setItem(this.key, JSON.stringify(data))
  }
}

export default () => {
  const directus = createDirectus<CustomDirectusTypes>(`${import.meta.env.VITE_DIRECTUS_URL}`)
  const client = directus.with(graphql()).with(rest()).with(authentication('json', { storage: new LocalStorage('directus-auth') }))

  const { getRole } = useRoles()

  const me = useState<DirectusUsers | undefined>('me')

  const hydrateUser = (user: DirectusUsers): DirectusUsers => ({
    ...user,
    role: getRole(user),
  })

  // const getUser = () => (meStorage.get() as DirectusUsers)
  const getUser = async (lazy: boolean = false): Promise<DirectusUsers | undefined> => {
    return new Promise((resolve) => {
      try {
        client.request(readMe())
          .then((res) => {
            me.value = hydrateUser(res as DirectusUsers)
            resolve(me.value)
          })
          .catch(() => {
            me.value = undefined
            resolve(me.value)
          })
      }
      catch (e) {
        me.value = undefined
        resolve(me.value)
      }
    })
  }

  const login = async (email: string, password: string) => {
    let me: DirectusUsers | undefined
    await client.login(email, password).then(async () => {
      me = await getUser()
    }).catch(() => {
      me = undefined
    })
    return me
  }

  const logout = async () => {
    await client.logout()
    me.value = undefined
    navigateTo('/login')
  }

  const sort = (a: { sort: number }, b: { sort: number }): 1 | -1 | 0 | void => {
    if (a.sort < b.sort)
      return -1
    if (a.sort > b.sort)
      return 1
    return 0
  }

  return {
    directus,
    client,
    updateMe,
    aggregate,
    uploadFiles,
    importFile,
    login,
    logout,
    getUser,
    readUsers,
    me,
    readItems,
    readItem,
    updateItem,
    createItem,
    deleteItem,
    sort,
    utilitySort,
  }
}
