import { default as _91lessonId_93byJ6HWu5DAMeta } from "/app/pages/[sea]/course/[courseId]/[sectionId]/[lessonId].vue?macro=true";
import { default as indexJNm451eiQyMeta } from "/app/pages/[sea]/course/[courseId]/[sectionId]/index.vue?macro=true";
import { default as indexwJ3oSinQHvMeta } from "/app/pages/[sea]/course/[courseId]/index.vue?macro=true";
import { default as indexqOlpGmlOHwMeta } from "/app/pages/[sea]/course/index.vue?macro=true";
import { default as indexSgPJh7G0VXMeta } from "/app/pages/[sea]/index.vue?macro=true";
import { default as me5pivqhK56qMeta } from "/app/pages/[sea]/me.vue?macro=true";
import { default as _91courseId_93mVtzzAw9mxMeta } from "/app/pages/admin/courses/[courseId].vue?macro=true";
import { default as indextPPVj2Bs7nMeta } from "/app/pages/admin/courses/index.vue?macro=true";
import { default as _91lessonId_93FTdNAP0fy4Meta } from "/app/pages/admin/courses/lesson/[lessonId].vue?macro=true";
import { default as indexLjSShoxzjkMeta } from "/app/pages/admin/courses/lesson/index.vue?macro=true";
import { default as dashboardsT6NVa0f3kMeta } from "/app/pages/admin/dashboard.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as indexD5GbCQU91xMeta } from "/app/pages/admin/media/index.vue?macro=true";
import { default as indexWmvg1ZKjPZMeta } from "/app/pages/admin/members/index.vue?macro=true";
import { default as profilejkleCjRaDPMeta } from "/app/pages/admin/profile.vue?macro=true";
import { default as settingsSSL6HVn0yEMeta } from "/app/pages/admin/settings.vue?macro=true";
import { default as imprintXQz1X9eYwAMeta } from "/app/pages/imprint.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as indexnwyNS3d1VsMeta } from "/app/pages/me/index.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
export default [
  {
    name: _91lessonId_93byJ6HWu5DAMeta?.name ?? "sea-course-courseId-sectionId-lessonId",
    path: _91lessonId_93byJ6HWu5DAMeta?.path ?? "/:sea()/course/:courseId()/:sectionId()/:lessonId()",
    meta: _91lessonId_93byJ6HWu5DAMeta || {},
    alias: _91lessonId_93byJ6HWu5DAMeta?.alias || [],
    redirect: _91lessonId_93byJ6HWu5DAMeta?.redirect,
    component: () => import("/app/pages/[sea]/course/[courseId]/[sectionId]/[lessonId].vue").then(m => m.default || m)
  },
  {
    name: indexJNm451eiQyMeta?.name ?? "sea-course-courseId-sectionId",
    path: indexJNm451eiQyMeta?.path ?? "/:sea()/course/:courseId()/:sectionId()",
    meta: indexJNm451eiQyMeta || {},
    alias: indexJNm451eiQyMeta?.alias || [],
    redirect: indexJNm451eiQyMeta?.redirect,
    component: () => import("/app/pages/[sea]/course/[courseId]/[sectionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJ3oSinQHvMeta?.name ?? "sea-course-courseId",
    path: indexwJ3oSinQHvMeta?.path ?? "/:sea()/course/:courseId()",
    meta: indexwJ3oSinQHvMeta || {},
    alias: indexwJ3oSinQHvMeta?.alias || [],
    redirect: indexwJ3oSinQHvMeta?.redirect,
    component: () => import("/app/pages/[sea]/course/[courseId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqOlpGmlOHwMeta?.name ?? "sea-course",
    path: indexqOlpGmlOHwMeta?.path ?? "/:sea()/course",
    meta: indexqOlpGmlOHwMeta || {},
    alias: indexqOlpGmlOHwMeta?.alias || [],
    redirect: indexqOlpGmlOHwMeta?.redirect,
    component: () => import("/app/pages/[sea]/course/index.vue").then(m => m.default || m)
  },
  {
    name: indexSgPJh7G0VXMeta?.name ?? "sea",
    path: indexSgPJh7G0VXMeta?.path ?? "/:sea()",
    meta: indexSgPJh7G0VXMeta || {},
    alias: indexSgPJh7G0VXMeta?.alias || [],
    redirect: indexSgPJh7G0VXMeta?.redirect,
    component: () => import("/app/pages/[sea]/index.vue").then(m => m.default || m)
  },
  {
    name: me5pivqhK56qMeta?.name ?? "sea-me",
    path: me5pivqhK56qMeta?.path ?? "/:sea()/me",
    meta: me5pivqhK56qMeta || {},
    alias: me5pivqhK56qMeta?.alias || [],
    redirect: me5pivqhK56qMeta?.redirect,
    component: () => import("/app/pages/[sea]/me.vue").then(m => m.default || m)
  },
  {
    name: _91courseId_93mVtzzAw9mxMeta?.name ?? "admin-courses-courseId",
    path: _91courseId_93mVtzzAw9mxMeta?.path ?? "/admin/courses/:courseId()",
    meta: _91courseId_93mVtzzAw9mxMeta || {},
    alias: _91courseId_93mVtzzAw9mxMeta?.alias || [],
    redirect: _91courseId_93mVtzzAw9mxMeta?.redirect,
    component: () => import("/app/pages/admin/courses/[courseId].vue").then(m => m.default || m)
  },
  {
    name: indextPPVj2Bs7nMeta?.name ?? "admin-courses",
    path: indextPPVj2Bs7nMeta?.path ?? "/admin/courses",
    meta: indextPPVj2Bs7nMeta || {},
    alias: indextPPVj2Bs7nMeta?.alias || [],
    redirect: indextPPVj2Bs7nMeta?.redirect,
    component: () => import("/app/pages/admin/courses/index.vue").then(m => m.default || m)
  },
  {
    name: _91lessonId_93FTdNAP0fy4Meta?.name ?? "admin-courses-lesson-lessonId",
    path: _91lessonId_93FTdNAP0fy4Meta?.path ?? "/admin/courses/lesson/:lessonId()",
    meta: _91lessonId_93FTdNAP0fy4Meta || {},
    alias: _91lessonId_93FTdNAP0fy4Meta?.alias || [],
    redirect: _91lessonId_93FTdNAP0fy4Meta?.redirect,
    component: () => import("/app/pages/admin/courses/lesson/[lessonId].vue").then(m => m.default || m)
  },
  {
    name: indexLjSShoxzjkMeta?.name ?? "admin-courses-lesson",
    path: indexLjSShoxzjkMeta?.path ?? "/admin/courses/lesson",
    meta: indexLjSShoxzjkMeta || {},
    alias: indexLjSShoxzjkMeta?.alias || [],
    redirect: indexLjSShoxzjkMeta?.redirect,
    component: () => import("/app/pages/admin/courses/lesson/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardsT6NVa0f3kMeta?.name ?? "admin-dashboard",
    path: dashboardsT6NVa0f3kMeta?.path ?? "/admin/dashboard",
    meta: dashboardsT6NVa0f3kMeta || {},
    alias: dashboardsT6NVa0f3kMeta?.alias || [],
    redirect: dashboardsT6NVa0f3kMeta?.redirect,
    component: () => import("/app/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexi8nCATZISJMeta?.name ?? "admin",
    path: indexi8nCATZISJMeta?.path ?? "/admin",
    meta: indexi8nCATZISJMeta || {},
    alias: indexi8nCATZISJMeta?.alias || [],
    redirect: indexi8nCATZISJMeta?.redirect,
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexD5GbCQU91xMeta?.name ?? "admin-media",
    path: indexD5GbCQU91xMeta?.path ?? "/admin/media",
    meta: indexD5GbCQU91xMeta || {},
    alias: indexD5GbCQU91xMeta?.alias || [],
    redirect: indexD5GbCQU91xMeta?.redirect,
    component: () => import("/app/pages/admin/media/index.vue").then(m => m.default || m)
  },
  {
    name: indexWmvg1ZKjPZMeta?.name ?? "admin-members",
    path: indexWmvg1ZKjPZMeta?.path ?? "/admin/members",
    meta: indexWmvg1ZKjPZMeta || {},
    alias: indexWmvg1ZKjPZMeta?.alias || [],
    redirect: indexWmvg1ZKjPZMeta?.redirect,
    component: () => import("/app/pages/admin/members/index.vue").then(m => m.default || m)
  },
  {
    name: profilejkleCjRaDPMeta?.name ?? "admin-profile",
    path: profilejkleCjRaDPMeta?.path ?? "/admin/profile",
    meta: profilejkleCjRaDPMeta || {},
    alias: profilejkleCjRaDPMeta?.alias || [],
    redirect: profilejkleCjRaDPMeta?.redirect,
    component: () => import("/app/pages/admin/profile.vue").then(m => m.default || m)
  },
  {
    name: settingsSSL6HVn0yEMeta?.name ?? "admin-settings",
    path: settingsSSL6HVn0yEMeta?.path ?? "/admin/settings",
    meta: settingsSSL6HVn0yEMeta || {},
    alias: settingsSSL6HVn0yEMeta?.alias || [],
    redirect: settingsSSL6HVn0yEMeta?.redirect,
    component: () => import("/app/pages/admin/settings.vue").then(m => m.default || m)
  },
  {
    name: imprintXQz1X9eYwAMeta?.name ?? "imprint",
    path: imprintXQz1X9eYwAMeta?.path ?? "/imprint",
    meta: imprintXQz1X9eYwAMeta || {},
    alias: imprintXQz1X9eYwAMeta?.alias || [],
    redirect: imprintXQz1X9eYwAMeta?.redirect,
    component: () => import("/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexnwyNS3d1VsMeta?.name ?? "me",
    path: indexnwyNS3d1VsMeta?.path ?? "/me",
    meta: indexnwyNS3d1VsMeta || {},
    alias: indexnwyNS3d1VsMeta?.alias || [],
    redirect: indexnwyNS3d1VsMeta?.redirect,
    component: () => import("/app/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: privacyg3gwBRGXatMeta?.name ?? "privacy",
    path: privacyg3gwBRGXatMeta?.path ?? "/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    alias: privacyg3gwBRGXatMeta?.alias || [],
    redirect: privacyg3gwBRGXatMeta?.redirect,
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  }
]