<script setup lang="ts">
import { useFavicon } from '@vueuse/core'

const ready = ref<boolean>(false)

useFavicon('/favicon.png')

onMounted(async () => {
  ready.value = true
})
</script>

<template>
  <div>
    <div v-if="ready">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
      <UNotifications />
    </div>
    <div v-else class="">
      <div class="flex items-center justify-center h-screen bg-gradient-to-b from-gray-100 to-gray-300">
        <UIcon name="i-ph-circle-notch-fill" class="animate-spin w-24 h-24 text-gray-500" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "assets/scss/site";
</style>
